import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Snapshot from '../pages/Snapshot/Snapshot';
import SnapshotRequest from '../pages/Snapshot/SnapshotRequest';
import StateChangeData from '../pages/Snapshot/StateChangeData';
import { Translation } from '../pages/Translations';
import { TranslationReport } from '../pages/Translations/Reports';
import { TranslationRequestForm } from '../pages/Translations/RequestForm';
import { HistoricalData } from '../pages/Translations/Reports/HistoricalData';
import { TranslationImportRequest } from '../pages/Translations/RequestForm/ImportJobRequest';
import { TranslationExportRequest } from '../pages/Translations/RequestForm/ExportJobUpdate';
import { TranslationContextFile } from '../pages/Translations/RequestForm/ContextFile';
import { TranslationExportDebug } from '../pages/Translations/RequestForm/ExportJobReport';
import { ReportContextFile } from '../pages/Translations/Reports/ContextFile';
import { ReportContextFileById } from '../pages/Translations/Reports/ContextFile/ContextFileById';
import { ReportExportReport } from '../pages/Translations/Reports/ExportReport/index';
import { ExportReportById } from '../pages/Translations/Reports/ExportReport/ExportReportById';

const Router = ({ isSnapshot }) => {

    return (
        <Routes>
            <Route path='/' element={<Home isSnapshotAccess={isSnapshot} />} />
            <Route path='/snapshot' element={<Snapshot />} />
            <Route path='/snapshot/snapshot-request' element={<SnapshotRequest />} />
            <Route path='/snapshot/state-change-data' element={<StateChangeData />} />
            <Route path='/translations' element={<Translation />} />
            <Route path='/translations/requestForm' element={<TranslationRequestForm />} />
            <Route path='/translations/requestForm/export-job-request' element={<TranslationExportRequest />} />
            <Route path='/translations/requestForm/import-job-request' element={<TranslationImportRequest />} />
            <Route path='/translations/requestForm/context-file' element={<TranslationContextFile />} />
            <Route path='/translations/requestForm/export-debug' element={<TranslationExportDebug />} />
            <Route path='/translations/reports' element={<TranslationReport />} />
            <Route path='/translations/reports/context-file' element={<ReportContextFile />} />
            <Route path='/translations/reports/context-file/:id' element={<ReportContextFileById />} />
            <Route path='/translations/reports/historical-data' element={<HistoricalData />} />
            <Route path='/translations/reports/export-report' element={<ReportExportReport />} />
            <Route path='/translations/reports/export-report/:id' element={<ExportReportById />} />
        </Routes>
    )
}
// implement nested route
export default Router