import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { HiMinus } from "react-icons/hi";
import { adGroupToken } from '../../redux/feature/auth/adGroupSlice';
import { userRole } from '../../redux/feature/auth/roleSlice';
import { useDispatch, useSelector } from 'react-redux';
import _isContains from '../utils/compareRole';
import { toggleSwitch } from '../../redux/feature/common/toggleSlice';

const Sidebar = ({ open, setOpen, isSnapshot, isTranslation }) => {

    const [submenu, setSubmenu] = useState(true);
    const [quickLinkMenu, setQuickLinkMenu] = useState(true);
    const [accessSnapshot, setAccessSnapshot] = useState(false);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.adGroupToken);
    const username = useSelector((state) => state.auth.userInfo?.username);
    const role = useSelector((state) => state.userRole);
    const switchValue = useSelector((state) => state.toggleSlice.active);
     
    useEffect(() => {
        dispatch(adGroupToken())
    }, []);

    // useEffect(() => {
    //     if (role.isSuccess) {
    //         const userRoles = role.roles[0].value;
    //         setAccessSnapshot(_isContains(userRoles, process.env.REACT_APP_SNAPSHOT_GROUP));
    //     }
    // }, [role.isSuccess]);

    useEffect(() => {
        if (token.isSuccess) {
            const values = {
                username: username,
                access_token: token.data[0].access_token
            }
            dispatch(userRole(values))
        }
    }, [token.isSuccess]);

    const handleOpen = () => {
        setSubmenu(!submenu);
    }
    const handleOpenLink = () => {
        setQuickLinkMenu(!quickLinkMenu);
    }

    function toggleSwitchHandler(value) {
         dispatch(toggleSwitch(value));
    }

    return (
        <div className={`nav-left-sidebar  ${switchValue ? `nav-left-sidebar-collapse` : `sidebar-dark`}`}>
            <div className='hamburger-desktop'>
                {
                    switchValue ? <MdKeyboardDoubleArrowRight size={40} color='#CD3B2C' style={{ cursor: "pointer" }} onClick={() => toggleSwitchHandler(false)} /> : <MdKeyboardDoubleArrowLeft style={{ cursor: "pointer" }} size={40} color='#CD3B2C' onClick={() => toggleSwitchHandler(true)} />
                }
            </div>

            <div className={`${switchValue ? 'menu-list-show' : 'menu-list'}`}>
                <nav className="navbar navbar-expand-lg navbar-light">

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>

                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">

                            <li className="nav-item" onClick={() => setOpen(!open)}>
                                <Link className="nav-link" to="/" >Home</Link>

                            </li>

                            {
                                isSnapshot &&
                                (
                                    <li className={`nav-item`}>
                                        <a className={`nav-link ${!submenu ? "active" : ""}`} href="#" aria-expanded="false" data-target="#submenu-1" aria-controls="submenu-1" onClick={handleOpen}>
                                            <div className={`${!submenu ? "parent-title" : ""}`}>Snapshot</div>
                                            <div className='icons-standout'>
                                                {submenu ? <GoPlus /> : <HiMinus />}

                                            </div>

                                        </a>

                                        <div className={`submenu ${submenu ? "collapse" : ""}`} >
                                            <ul className="nav flex-column">

                                                <li className="nav-item" onClick={() => setOpen(!open)}>
                                                    <Link className="nav-link" to='/snapshot/snapshot-request'>Snapshot Request Form</Link>
                                                </li>
                                                <li className="nav-item" onClick={() => setOpen(!open)}>
                                                    <Link className="nav-link" to="https://collab.lilly.com/sites/LillyNowSnapshotTST/Shared%20Documents/Forms/AllItems.aspx" target="_blank">Repository</Link>
                                                </li>
                                                <li className="nav-item" onClick={() => setOpen(!open)}>
                                                    <Link className="nav-link" to="/snapshot/state-change-data">State Change Data</Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                )
                            }

                            { isTranslation && (<li className="nav-item ">

                                <a className="nav-link" href="#" aria-expanded="false" data-target="#submenu-transaltion" aria-controls="submenu-transaltion">
                                    <div>Translation</div>
                                    <GoPlus />
                                </a>


                                <div id="submenu-transaltion" className="collapse submenu" >
                                    {/* submenu */}
                                </div>
                            </li>)}
                            <li className="nav-item ">

                                <a className="nav-link" href="#" aria-expanded="false" data-target="#submenu-refreshtargets" aria-controls="submenu-refreshtargets">
                                    <div>Refresh Targets</div>
                                    <GoPlus />
                                </a>
                                <div id="submenu-refreshtargets" className="submenu" >
                                    {/* Submenu */}
                                </div>
                            </li>
                            <li className="nav-item ">

                                <a className="nav-link" href="#" aria-expanded="false" data-target="#submenu-refreshtargets" aria-controls="submenu-refreshtargets">
                                    <div>Data Pull</div>
                                    <GoPlus />
                                </a>
                                <div id="submenu-refreshtargets" className="submenu" >
                                    {/* Submenu */}
                                </div>
                            </li>
                            <li className="nav-item ">

                                <a className="nav-link" href="#" aria-expanded="false" data-target="#submenu-refreshtargets" aria-controls="submenu-refreshtargets">
                                    <div>International APIs / Reports</div>
                                    <GoPlus />
                                </a>
                                <div id="submenu-refreshtargets" className="submenu" >
                                    {/* Submenu */}
                                </div>
                            </li>
                            <li className="nav-item ">

                                <a className="nav-link" href="#" aria-expanded="false" data-target="#submenu-refreshtargets" aria-controls="submenu-refreshtargets">
                                    <div>Testing Automation</div>
                                    <GoPlus />
                                </a>
                                <div id="submenu-refreshtargets" className="submenu" >
                                    {/* Submenu */}
                                </div>
                            </li>
                            <li className="nav-item ">

                                <a className="nav-link" href="#" aria-expanded="false" data-target="#submenu-refreshtargets" aria-controls="submenu-refreshtargets">
                                    <div>Contentful Synchronization</div>
                                    <GoPlus />
                                </a>
                                <div id="submenu-refreshtargets" className="submenu" >
                                    {/* Submenu */}
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${!quickLinkMenu ? "active" : ""}`} href="#" aria-expanded="false" data-target="#quickLinkMenu-1" aria-controls="quickLinkMenu-1" onClick={handleOpenLink}>
                                    <div className={`${!quickLinkMenu ? "parent-title" : ""}`}>Quick Links</div>
                                    <div className='icons-standout'>
                                        {quickLinkMenu ? <GoPlus /> : <HiMinus />}

                                    </div>

                                </a>

                                <div className={`submenu ${quickLinkMenu ? "collapse" : ""}`} >
                                    <ul className="nav flex-column">

                                        <li className="nav-item" onClick={() => setOpen(!open)}>
                                            <Link className="nav-link" to='https://now.lilly.com' target="_blank">Lilly Now</Link>
                                        </li>
                                        <li className="nav-item" onClick={() => setOpen(!open)} >
                                            <Link className="nav-link" to="https://be.contentful.com/login" target="_blank">Contentful</Link>
                                        </li>
                                        <li className="nav-item" onClick={() => setOpen(!open)}>
                                            <Link className="nav-link" to="https://lilly-jira.atlassian.net/jira/your-work" target="_blank">Jira</Link>
                                        </li>
                                        <li className="nav-item" onClick={() => setOpen(!open)}>
                                            <Link className="nav-link" to="https://lilly-confluence.atlassian.net" target="_blank">Confluence</Link>
                                        </li>

                                    </ul>
                                </div>
                            </li>



                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Sidebar