import React, { useEffect, useState } from "react";
import moment from "moment";
import Breadcrumb from "../../../../component/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { ReportData } from "../../../../redux/feature/translation/report/ReportDataSlice";
import { exportUpdateRequestForm } from "../../../../redux/feature/translation/export/exportUpdateFormSlice";

export const TranslationExportRequest = () => {
  const auth = useSelector((state) => state.auth);
  const currentTime = new Date();
  const confirmationMessage =
    "You would receive a confirmation when the export job is complete. Locate and download the export files (published and static) under Reports > Export. Send these files to the vendor along with the Context file.";

  const responseExportReport = useSelector((state) => state.ReportData);
  const dispatch = useDispatch();

  const [sampleData, setSampleData] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    const request = {
      type: "exportReport",
      download: false,
    };
    dispatch(ReportData(request));
  }, [dispatch]);

  useEffect(() => {
    if (responseExportReport.data && responseExportReport.data.length) {
      const data =
        responseExportReport.data[0]?.value?.map((obj) => ({
          requestId: obj.request_id,
          timestamp: obj.timestamp,
          author: obj.author,
          email: obj.email_id,
        })) || [];

      setSampleData(data);

      const formattedOptions = data.map((obj) => {
        const formattedTimestamp = moment(obj.timestamp).format(
          "DD/MMM/YYYY hh:mm A"
        );
        return {
          value: obj.requestId,
          label: `Request Id - ${obj.requestId}, ${obj.author}, ${formattedTimestamp}`,
        };
      });

      setDropdownOptions(formattedOptions);
      setIsLoading(false);
    }
  }, [responseExportReport]);

  const handleSubmit = () => {
    if (selectedRequestId) {
      console.log("selectedRequestId", selectedRequestId);
      dispatch(exportUpdateRequestForm(selectedRequestId));
    } else {
      alert("Please select a request ID before submitting.");
    }
  };

  return (
    <div className="dashboard-ecommerce">
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Breadcrumb />
            <div className="widget">
              <div className="card-header">Export Job Request</div>
              <hr className="hr" />
              <div className="px-3 py-2">
                <div>
                  Export Job Request will update eligible entries at Contentful
                  into <b>In Translation</b> mode, rendering them in locked
                  status, until Import Job Request is submitted. Only an ADMIN
                  and a SUPER ADMIN would have access to edit such entries. Thus
                  please exercise caution.
                </div>
                <div className="py-2">
                  Entries that are eligible for translation:
                </div>
                <ul>
                  <li>
                    Last updated by should NOT be{" "}
                    <b>Translation Integration</b>.
                  </li>
                  <li>
                    Entry should be in <b>Published Status</b>.
                  </li>
                  <li>
                    Entry should be from the below-mentioned content type.
                  </li>
                </ul>
              </div>
              <div className="container">
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    User Name
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={auth.userInfo?.name}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    Date & Time
                  </div>
                  <input
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={moment(currentTime).format("MM/DD/YYYY hh:mm a")}
                    disabled
                  />
                </div>
                <div className="row align-items-center my-4">
                  <div className="text-black col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12">
                    Select Request ID
                  </div>
                  <select
                    className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 px-2 py-1"
                    value={selectedRequestId}
                    onChange={(e) => setSelectedRequestId(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="" disabled>
                      {isLoading ? "Loading..." : "Select a request id"}
                    </option>
                    {dropdownOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pb-2 pt-2">
                  <div className="row">
                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12" />
                    <button
                      className="submitButton col-xl-2 col-lg-6 col-md-6 col-sm-12 col-12"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading} 
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="text-teal pb-2">{confirmationMessage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};